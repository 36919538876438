import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EventProcessor = ({ onLogout }) => {
  const [inputText, setInputText] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [output, setOutput] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [disclaimerVisible, setDisclaimerVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // New state for form submission

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setGuestEmail(email);
    // Validate email format
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(guestEmail)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    setFormSubmitted(true); // Set form submission state to true

    parseText(inputText, guestEmail);
  };

  const parseText = (text, email) => {
    const meetingRoomRegex = /Block address and name:\s*(.*?)\s*\d+\s*people\./;
    const eventDateRegex = /Meeting Date:\s*(\d{1,2}\/\d{1,2}\/\d{4})/;
    const ticketNumberRegex = /Ticket number\s*(\d+)/;
    // const startTimeRegex = /Start time:\s*(\d{1,2}:\d{2})\s*([AP]M)/i;
    const startTimeRegex =
      /Start time:\s*((?:1[0-2]|0?[1-9]):[0-5][0-9]\s*(?:AM|PM|am|pm)?)/i;

    if (
      !meetingRoomRegex.test(text) ||
      !eventDateRegex.test(text) ||
      !ticketNumberRegex.test(text) ||
      !startTimeRegex.test(text)
    ) {
      const errors = [];
      if (!meetingRoomRegex.test(text)) {
        errors.push(
          "Meeting room information is missing or in the wrong format."
        );
      }
      if (!eventDateRegex.test(text)) {
        errors.push("Event date is missing or in the wrong format.");
      }
      if (!ticketNumberRegex.test(text)) {
        errors.push("Ticket number is missing or in the wrong format.");
      }
      if (!startTimeRegex.test(text)) {
        errors.push("Start time is missing or in the wrong format.");
      }
      const errorMessage =
        "Wrong input format, please check the text:\n" + errors.join("\n");
      setOutput({ error: errorMessage });
      alert(errorMessage);
      return;
    }

    let [startTime, startPeriod] = text
      .match(startTimeRegex)
      .slice(1)
      .map((str) => str?.trim());
    startTime = startTime.replace(/\s*(AM|PM|am|pm)\s*$/, "");

    const meetingRoomMatch = text.match(meetingRoomRegex);
    const meetingRoom = meetingRoomMatch ? meetingRoomMatch[1] : "";

    const eventDateMatch = text.match(eventDateRegex);
    const eventDate = eventDateMatch ? eventDateMatch[1] : "";

    const ticketNumberMatch = text.match(ticketNumberRegex);
    const ticketNumber = ticketNumberMatch ? ticketNumberMatch[1] : "";

    // const startTimeMatch = text.match(startTimeRegex);
    // let startTime = startTimeMatch ? startTimeMatch[1].trim() : '';
    // let startPeriod = startTimeMatch && startTimeMatch[2] ? startTimeMatch[2].toUpperCase() : '';

    if (!startPeriod) {
      const [hours] = startTime.split(":").map(Number);
      startPeriod = hours >= 7 && hours < 12 ? "AM" : "PM";
    }

    startTime += ` ${startPeriod}`;

    // if (!startPeriod) {
    //     const [hours, minutes] = startTime.split(':').map(Number);
    //     if (hours >= 7 && hours < 12) {
    //         startPeriod = 'AM';
    //     } else {
    //         startPeriod = 'PM';
    //         if (hours > 12) startTime = `${hours - 12}:${minutes.toString().padStart(2, '0')}`;
    //     }
    //     startTime = `${startTime} ${startPeriod}`;
    // }

    const [startHours, startMinutes] = startTime
      .split(/[:\s]/)
      .map((val, idx) => (idx < 2 ? Number(val) : val));
    let endHours = startHours + Math.floor((startMinutes + 30) / 60);
    let endMinutes = (startMinutes + 30) % 60;
    let endPeriod = startPeriod;

    if (endHours >= 12) {
      endPeriod = endPeriod === "AM" ? "PM" : "AM";
      if (endHours > 12) endHours -= 12;
    }

    if (endHours === 12 && endMinutes === 0) {
      endPeriod = endPeriod === "AM" ? "PM" : "AM";
    }

    const endTime = `${endHours}:${endMinutes
      .toString()
      .padStart(2, "0")} ${endPeriod}`;

    const extractedEmail = email;

    const allText = encodeURIComponent(text.replace(/\n/g, " ").trim());

    const formatDateTimeForCalendar = (date, time) => {
      const [month, day, year] = date.split("/");
      let [hours, minutes] = time.split(/[:\s]/);
      const period = time.slice(-2);
      if (period === "PM" && hours !== "12") hours = String(Number(hours) + 12);
      if (period === "AM" && hours === "12") hours = "00";
      return `${year}${month.padStart(2, "0")}${day.padStart(
        2,
        "0"
      )}T${hours.padStart(2, "0")}${minutes.padStart(2, "0")}00`;
    };

    const calendarStart = formatDateTimeForCalendar(eventDate, startTime);
    const calendarEnd = formatDateTimeForCalendar(eventDate, endTime);

    const calID = "mississauga.meeting_room_support@roche.com";

    const calendarUrl = `https://calendar.google.com/calendar/r/eventedit?src=${calID}&text=${encodeURIComponent(
      meetingRoom + " Support | PRISM " + ticketNumber
    )}&dates=${calendarStart}/${calendarEnd}&details=${allText}&add=${guestEmail}`;

    setOutput({
      meetingRoom,
      eventDate,
      ticketNumber,
      startTime,
      endTime,
      extractedEmail,
      calendarUrl,
    });
  };

  const toggleDisclaimer = () => {
    setDisclaimerVisible(!disclaimerVisible);
  };

  const handleLogout = () => {
    onLogout();
  };

  return (
    <div className="container pt-3">
      <div className="row">
        <div className="col-12">
          {/* <h2>Events Processing Tool</h2> */}
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <h2>Text Processing Tool</h2>
              <form onSubmit={handleSubmit} className="my-4">
                <div className="form-group">
                  <textarea
                    id="inputText"
                    className="form-control"
                    value={inputText}
                    onChange={handleInputChange}
                    rows="10"
                    placeholder="Enter the text here..."
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="email"
                    id="guestEmail"
                    className={`form-control ${emailError ? "is-invalid" : ""}`}
                    value={guestEmail}
                    onChange={handleEmailChange}
                    placeholder="Enter guest email"
                  />
                  {emailError && (
                    <div className="invalid-feedback">{emailError}</div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                  Submit
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-danger mt-3"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </form>

              <div className="disclaimer mt-3">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="icon"
                  onClick={toggleDisclaimer}
                  style={{ fontSize: "2em", cursor: "pointer" }}
                />
                {disclaimerVisible && (
                  <div className="text">
                    <ul>
                      <li>
                        It's important to double-check the times provided.
                      </li>
                      <li>
                        This tool may not work correctly with requests that are
                        missing information such as room names.
                      </li>
                      <li>
                        This tool assumes a duration of 30 minutes after the
                        start time.
                      </li>
                      <li>
                        Remember to switch to the correct calendar when creating
                        the event.
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 pb-3">
              {output && (
                <div className="card mt-4 bg-dark text-light pb-3">
                  <div className="card-body">
                    <h2 className="card-title">Extracted Information</h2>
                    <hr></hr>
                    <p className="card-text">
                      <strong>Meeting Room:</strong>{" "}
                      <strong>
                        <span className="text-warning">
                          {output.meetingRoom}
                        </span>
                      </strong>
                    </p>
                    <p className="card-text">
                      <strong>Event Date:</strong>{" "}
                      <strong>
                        <span className="text-warning">{output.eventDate}</span>
                      </strong>
                    </p>
                    <p className="card-text">
                      <strong>Ticket Number:</strong>{" "}
                      <strong>
                        <span className="text-warning">
                          {output.ticketNumber}
                        </span>
                      </strong>
                    </p>
                    <p className="card-text">
                      <strong>Start Time:</strong>{" "}
                      <strong>
                        <span className="text-warning">{output.startTime}</span>
                      </strong>
                    </p>
                    <p className="card-text">
                      <strong>End Time:</strong>{" "}
                      <strong>
                        <span className="text-warning">{output.endTime}</span>
                      </strong>{" "}
                      / Assuming 30 min.
                    </p>
                    <p className="card-text">
                      <strong>Guest Email:</strong>{" "}
                      <strong>
                        <span className="text-warning">
                          {output.extractedEmail}
                        </span>
                      </strong>
                    </p>
                    {formSubmitted && (
                      <a
                        href={`https://calendar.google.com/calendar/u/0/r/search?q=${output.ticketNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-info"
                      >
                        Search Event
                      </a>
                    )}
                    &nbsp; &nbsp;
                    <a
                      href={output.calendarUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-success"
                    >
                      Create Event
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventProcessor;
