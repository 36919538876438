import React, { useState } from 'react';
import Quiz from "./Components/EventProcessor"
import Login from './Components/Login';

const App = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
};



return (
  <div>
      {!isLoggedIn ? (
          <Login onLogin={handleLogin} />
      ) : (
          <Quiz onLogout={handleLogout} /> // Pass onLogout function as a prop
      )}
  </div>
);
};


export default App