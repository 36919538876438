// Login.js
import React, { useState } from 'react';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Hardcoded username and password for demonstration
        const hardcodedUsername = 'user123';
        const hardcodedPassword = 'pass123';

        if (username === hardcodedUsername && password === hardcodedPassword) {
            // If the credentials match, call the onLogin callback to indicate successful login
            onLogin();
        } else {
            // If the credentials don't match, display an error message
            setError('Invalid username or password');
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className='col-lg-6 bg-dark text-light mt-5 p-5'>
                <h2>Login</h2>
                <hr></hr>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-warning">Login</button>
                {error && <p className="text-warning mt-3">{error}</p>}
            </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
